@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.swiper-wrapper {
  align-items: center;
}

@media (max-width: 480px) {
  #fc_frame {
    bottom: 2% !important;
  }
}
@media (min-width: 1395px) {
  #fc_frame {
    bottom: 13% !important;
  }
}
@media (min-width: 1600px) {
  #fc_frame {
    bottom: 11% !important;
  }
}

ul {
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

* {
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth !important;
}

html {
  scroll-padding-top: 215px; /* height of your navbar */
}

.swiper-news .swiper-slide {
  margin-right: 0 !important;
}

.chat-fc-form-outer {
  bottom: 120px !important;
}

.fc-form p {
  margin-top: 15px;
}

.chat-fc-form input {
  text-indent: 10px;
}
