.slide {
  display: flex;
  gap: 16px;
}
.slide-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 400px;
  color: white;
  background-color: black;
  transition: all 0.75s ease-in-out;
}

.slide-active {
  width: 700px;
}
.img-slide {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.card-product {
  cursor: pointer;
}
.card-product:hover {
  transform: scale(1.02) perspective(0px);
  box-shadow: 4px 4px 4px rgba(60, 60, 93, 0.33);
}

@media (max-width: 576px) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }
}
