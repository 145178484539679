@font-face {
  font-family: 'GotHam-Vu';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('../../public/fonts/Gotham-VU-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'GotHam-Vu';
  font-weight: 500;
  font-display: swap;
  font-style: medium;
  src: url('../../public/fonts/Gotham-VU-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'GotHam-Vu';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('../../public/fonts/Gotham-VU-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Gotham';
  font-weight: 450;
  font-style: normal;
  font-display: swap;
  src: url('../../public/fonts/SVN-Gotham-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SVN-Gotham';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../../public/fonts/SVN-Gotham-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SVN-Gotham';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('../../public/fonts/SVN-Gotham-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../../public/fonts/Nunito-Bold.ttf') format('truetype');
}
